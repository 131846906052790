import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Input, Label, Row } from "reactstrap";

import call from "../../Assets/icons/call.svg";
import email from "../../Assets/icons/email.svg";
// import location from "../../Assets/icons/location.svg";
import contactUs from "../../Assets/images/contact-us.png";
import Button from "../../Components/Buttons/Button";

import axiosInstance from "../../axiosInterceptor";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const DashboardContactUs = () => {
  const [setting, setSetting] = useState([]);
  const [contact, setContact] = useState([]);

  const formatContactNumber = (countryName, contactNumber) => {
    switch (countryName) {
      case "India":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          7
        )} ${contactNumber.slice(7)}`;
      case "Canada":
        return `+${contactNumber.slice(0, 1)} (${contactNumber.slice(
          1,
          4
        )}) ${contactNumber.slice(4, 7)}-${contactNumber.slice(7)}`;
      case "Netherlands":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          5
        )} ${contactNumber.slice(5, 7)} ${contactNumber.slice(7)}`;
      default:
        return contactNumber;
    }
  };

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .post("student/contactUs", profile)
      .then((res) => {
        // console.log(res)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSettingData = async () => {
    try {
      const res = await axiosInstance.get(`/admin/setting/getSetting`);
      setSetting(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchContactData = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/contactNumber/getContactNumbers`
      );
      setContact(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingData();
    fetchContactData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Contact Us | Get Personalized Academic Success with DoubtQ | Online
          Question Help
        </title>
        <meta
          name="description"
          content="Welcome to the Contact Us of DoubtQ"
        />
        <meta property="og:title" content="Contact Us - DoubtQ" />
        <meta
          property="og:description"
          content="Explore personalized academic help on DoubtQ's Contact Us"
        />
      </Helmet>
      <div className="faq-top">
        <Container className="py-5 w-85 dashboard">
          <Row className="py-5">
            <Col className="py-5">
              <h1
                className="py-5"
                style={{ fontSize: "2.5em", fontWeight: "600" }}
              >
                Have some questions?
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="add-contact">
        <Row>
          {contact
            .filter((c) => c.showInFooter || c.showInHeader)
            .map((c, index) => {
              if (c.showInHeader) {
                return (
                  <Col md="6" className="mb-2" key={index}>
                    <Card
                      className="contact-card"
                      style={{ borderRadius: "11px", cursor: "pointer" }}
                    >
                      <div className="w-100 text-start">
                        <img
                          src={call}
                          alt="call icon"
                          style={{ width: "24px", height: "24px" }}
                        />
                        <h4 className="pt-3 our-location">Student support</h4>
                        <Link
                          to={`tel:${c.contactNumber}`}
                          style={{
                            position: "absolute",
                            textDecoration: "none",
                          }}
                        >
                          <h4
                            className="contact-add"
                            style={{ color: "rgb(133, 133, 133)" }}
                          >
                            {c.countryName}:{" "}
                            {formatContactNumber(
                              c.countryName,
                              c.contactNumber
                            )}
                          </h4>
                        </Link>
                      </div>
                    </Card>
                  </Col>
                );
              }
              return null;
            })}
          <Col md="6" className="mb-2">
            <Card
              className="contact-card"
              style={{ borderRadius: "11px", cursor: "pointer" }}
            >
              <div className="w-100 text-start">
                <img
                  src={email}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
                <h4 className="pt-3 our-location">Email Address</h4>
                <h5 style={{ color: "rgb(133 133 133)" }}>
                  <Link
                    to={`mailto:${setting?.contactEmail}`}
                    style={{
                      color: "#666",
                      textDecoration: "none",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  >
                    <h4 className="contact-add">{setting?.contactEmail}</h4>
                  </Link>
                </h5>
              </div>
            </Card>
          </Col>
          {/* <Col md="4" className="mb-2">
            <Card className="contact-card" style={{ borderRadius: "11px" }}>
              <div className="w-100 text-start">
                <img
                  src={location}
                  alt=""
                  style={{ width: "27px", height: "27px" }}
                />
                <h4 className="pt-3 our-location">Our Location</h4>
                <h4
                  style={{ color: "rgb(133 133 133)" }}
                  className="contact-add"
                >
                  B-603, Sky View Heights, Behind Midas Square, Surat, Gujarat,
                  395010
                </h4>
              </div>
            </Card>
          </Col> */}
        </Row>
      </Container>

      <Container className="add-contact mb-4">
        <Row>
          <Col md="6">
            <img
              className="w-100"
              src={contactUs}
              alt=""
              style={{ borderRadius: "1.5rem" }}
            />
          </Col>
          <Col md="6">
            <Card className="contactus-card text-start contactus-card-m">
              <h1 className="text-start w-100 fw-semi-bold">
                You Can Contact With Me
              </h1>
              <Form
                className="w-100 pt-5"
                onSubmit={handleSubmit}
                style={{ zIndex: 9999 }}
              >
                <Label
                  style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}
                >
                  Full Name
                </Label>
                <Input
                  type="text"
                  name="name"
                  className="w-100 py-4"
                  value={profile.name}
                  onChange={handleChange}
                  placeholder="User Full Name"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label
                  className="mt-4"
                  style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}
                >
                  Mobile
                </Label>
                <Input
                  type="number"
                  name="phoneNumber"
                  className="w-100 py-4"
                  value={profile.phoneNumber}
                  onChange={handleChange}
                  placeholder="Your Number"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label
                  className="mt-4"
                  style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}
                >
                  Email Id
                </Label>
                <Input
                  type="text"
                  name="email"
                  className="w-100 py-4"
                  value={profile.email}
                  onChange={handleChange}
                  placeholder="abc@gmail.com"
                  style={{ fontSize: "1.2em", color: "#949494" }}
                />

                <Label
                  className="mt-4"
                  style={{ fontSize: "1.5em", fontWeight: "fw-semi-bold" }}
                >
                  Message
                </Label>
                <Input
                  type="textarea"
                  name="message"
                  className="w-100 py-4"
                  value={profile.message}
                  onChange={handleChange}
                  placeholder="Write your message here"
                  style={{
                    fontSize: "1.2em",
                    color: "#949494",
                    height: "150px",
                  }}
                />

                <Button
                  type="submit"
                  varient="linear-gradient-primary"
                  className="mt-5 w-100"
                  text={"Send Message"}
                ></Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardContactUs;
