import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import whatsapp from "../Assets/images/whatsapp.png";
import axiosInstance from "../axiosInterceptor";

const Nav = () => {
  const [contact, setContact] = useState([]);

  const formatContactNumber = (countryName, contactNumber) => {
    switch (countryName) {
      case "India":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          7
        )} ${contactNumber.slice(7)}`;
      case "Canada":
        return `+${contactNumber.slice(0, 1)} (${contactNumber.slice(
          1,
          4
        )}) ${contactNumber.slice(4, 7)}-${contactNumber.slice(7)}`;
      case "Netherlands":
        return `+${contactNumber.slice(0, 2)} ${contactNumber.slice(
          2,
          5
        )} ${contactNumber.slice(5, 7)} ${contactNumber.slice(7)}`;
      default:
        return contactNumber;
    }
  };

  const fetchContactData = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/contactNumber/getContactNumbers`
      );
      setContact(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  return (
    <div>
      <div className="bg-color-darker pt--10 pb--10 pt-3 pb-3 header-space-betwween">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="color-white d-flex align-items-center justify-content-center nav-new">
                {contact
                  .filter((c) => c.showInFooter || c.showInHeader)
                  .map(
                    (c, index) =>
                      c.showInHeader && (
                        <div
                          key={index}
                          className="d-sm-block d-md-block mr--10 Whatsapp-any-query"
                        >
                          <Link
                            className="color-white text-decoration-none"
                            to={`https://wa.me/${c.contactNumber}`}
                            target="_blank"
                          >
                            <img
                              src={whatsapp}
                              alt="whatsapp"
                              height={25}
                              width={25}
                              className="mb-2"
                            />
                          </Link>
                          <span className="mx-2 mt-2">
                            WhatsApp for any query :
                          </span>
                          <Link
                            className="color-white num-hover text-decoration-none"
                            to={`https://wa.me/${c.contactNumber}`}
                            target="_blank"
                          >
                            <i className="fab fa-whatsapp" />
                            {/* {c.countryName}:{" "} */}
                            {formatContactNumber(
                              c.countryName,
                              c.contactNumber
                            )}
                          </Link>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
